import "./Content.css";

const Content = () => {
    return (
        <div className="Content">
            <h1 className="Content-title">Services Offered</h1>
            <div className="Content-service">
                <h2 className="Content-service-title">ADAS Calibration</h2>
                <div className="Content-service-description">
                    <div>&bull; We offer precise calibration services for Advanced Driver Assistance Systems (ADAS).</div>
                    <div>&bull; Ensure your vehicle's safety features are functioning correctly with our state-of-the-art equipment and expertise.</div>
                </div>
            </div>
            <div className="Content-service">
                <h2 className="Content-service-title">RemindMe App Tool</h2>
                <div className="Content-service-description">
                    <div>&bull; RemindMe is a user-friendly app designed to help you keep track of your important events and tasks.</div>
                    <div>&bull; Set reminders and receive notifications via SMS or email to stay organized and on top of your schedule.</div>
                </div>
            </div>
        </div>
    )
}

export default Content;