import "./Footer.css"

const Footer = () => {
    return (
        <div className="Footer">
            <div><h3>Please contact <a href="mailto:support@amsksolutions.com">support@amsksolutions.com</a> for any questions.</h3></div>
            <div>2024 AMSK Solutions. All rights reserved.</div>
        </div>

    )
}

export default Footer;